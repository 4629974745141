<template>
  <div v-if="timer.status" class="second-timer-block">
    <div
      class="second-timer-block__title"
      v-html="title"
    />
    <div
      class="second-timer-block__subtitle"
      v-html="subtitle"
    />
    <div class="second-timer-block__container">
      <div class="second-timer-block__timer">
        <!-- countdown -->
        <div class="second-countdown">
          <div class="second-countdown__count">
            <span class="second-countdown__number">
              {{ formatNumber(timer.days) }}
            </span>
            <span class="second-countdown__colon">:</span>
          </div>
          <div class="second-countdown__title">
            <span>дней</span>
          </div>
        </div>

      </div>
      <div class="second-timer-block__timer">
        <!-- countdown -->
        <div class="second-countdown">
          <div class="second-countdown__count">
            <span class="second-countdown__number">
              {{ formatNumber(timer.hours) }}
            </span>
            <span class="second-countdown__colon">:</span>
          </div>
          <div class="second-countdown__title">
            <span>часов</span>
          </div>
        </div>

      </div>
      <div class="second-timer-block__timer">
        <!-- countdown -->
        <div class="second-countdown">
          <div class="second-countdown__count">
            <span class="second-countdown__number">
              {{ formatNumber(timer.minutes) }}
            </span>
          </div>
          <div class="second-countdown__title">
            <span>минут</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SecondTimer",
  inject: ["mainStore"],
  data() {
    return {
      interval: null,
      timer: {
        days: 0,
        hours: 0,
        minutes: 0,
        status: false
      }
    }
  },
  props: {
    date: {
      type: Date,
    },
    title: {
      type: String,
      default: "До повышения цен",
    },
    subtitle: {
      type: String,
      default: "До повышения цен",
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.init();
  },
  unmounted() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    init() {
      if (this.interval) return;
      this.interval = setInterval(this.calcTime, 1000 * 60);
      this.calcTime();
    },
    calcTime() {
      // const time = '2024-06-31T00:23:59';
      const time = this.date;

      var today = new Date(time);
      var current = new Date();
      const timestamp = today.getTime();
      var now = current.getTime();

      var distance = timestamp - now;
      this.timer = {
        days: Math.floor(distance / ((1000 * 60 * 60 * 24))),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        status: now < timestamp
      };
    },
    formatNumber(val) {
      return val < 10 ? '0' + val : val;
    }
  }
};
</script>

<style lang="css" scoped>
.second-timer-block {
  width: 310px;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border-radius: 24px;

}
@media (max-width: 768px) {
  .second-timer-block {
    width: 100vw;
    border-radius: 0;
    opacity: 0.9;
  }
}
.second-timer-block__title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23.1px;
  font-family: "Oranienbaum", "Times New Roman", sans-serif;
  text-transform: uppercase;
  color: #3F4440;
  text-align: center;
}

.second-timer-block__subtitle {
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Mabry Pro";
  line-height: 14px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #3F4440CC;
}

.second-timer-block__container {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.second-countdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 54px;
}

.second-countdown__count {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  font-size: 42px;
  font-family: "Oranienbaum", "Times New Roman", sans-serif;
  line-height: 1em;
  letter-spacing: -0.02em;
  color: #C56046;
}

.second-countdown__colon {
  position: relative;
  bottom: 5px;
  opacity: 0.6;
}

.second-countdown__number {
  min-width: 33px;
}

.second-countdown__title {
  /* padding-left: 12px; */
  font-weight: 400;
  font-size: 12px;
  font-family: "Mabry Pro";
  line-height: 1.2em;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #3F4440CC;
}
</style>
