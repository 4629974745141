<template>
  <footer class="footer">
    <div class="container">
      <div
        class="footer__top d-flex justify-content-between align-items-center"
      >
        <div class="footer__logo">
          <router-link to="/">
            <img
              loading="lazy"
              src="@i/logo-gray.svg"
              width="122"
              height="32"
              alt="Very"
            />
          </router-link>
        </div>
        <div v-if="$social" class="footer__cosial">
          <ul>
            <li v-for="(value, name) in $social" :key="name">
              <a
                :href="value.url"
                :aria-label="'Мы в ' + value.name"
                target="_blank"
                rel="noreferrer"
                ><Icon :icon="name"
              /></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__middle">
        <div class="footer__nav">
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                data-popup-with-chats
                data-popup-with-chats-title="Получить буклет"
                data-popup-with-chats-source-id="Получить буклет"
              >
                <span>Получить буклет</span>
              </a>
            </li>
            <li>
              <router-link :to="{ name: 'Documents' }"> Документы </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'News' }"> Новости </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Construction' }">
                Ход строительства
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'PlanArchive' }">
                Выбрать квартиру
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Contacts' }"> Контакты </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__bottom d-md-flex">
        <div class="footer__company">
          <a href="https://gk-osnova.ru/" target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="@i/company-gray.svg"
              width="136"
              height="40"
              alt="Группа компаний Основа"
            />
          </a>
        </div>
        <div class="footer__policy">
          <div class="footer__agent">
            <span
              class="footer__agent-link"
              role="button"
              data-popup-with-chats
              data-popup-with-chats-title="Агентствам недвижимости по вопросам сотрудничества"
              data-popup-with-chats-source-id="Агентства недвижимости"
            >
              Агентствам недвижимости по вопросам сотрудничества
            </span>
          </div>
          <p>
            Настоящий сайт носит исключительно информационный характер.
            Продолжая просмотр страниц сайта вы&nbsp;соглашаетесь со&nbsp;всеми
            условиями
            <router-link :to="{ name: 'Policy' }"
              >Пользовательского соглашения </router-link
            >и
            <a href="/Политика_обработки_персональных_данных.pdf" target="_blank">
              Политикой обработки персональных данных</a>
            . &copy;&nbsp;2021&nbsp;<a
              href="https://gk-osnova.ru/"
              target="_blank"
              rel="noopener"
              >АО ГК&nbsp;&laquo;Основа&raquo;</a
            >. Проектная документация
            <a href="https://legal-very.ru/" target="_blank" rel="noopener">
              на&nbsp;сайте застройщика </a
            >.
          </p>
        </div>
        <div class="footer__dev">
          <a href="https://devess.com/" target="_blank" rel="noopener">
            <img
              loading="lazy"
              src="@i/devess.svg"
              width="112"
              height="42"
              alt="Брендинговое агентство Devess"
            />
            <span
              >Дизайн и&nbsp;разработка веб-сайта Devess Branding Agency.</span
            >
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style scoped>
.footer {
  z-index: 49;
  display: block;
  margin-top: auto;
  padding: 33px 0;
  border-top: 1px solid #e5e5e5;
  background: #fff;
  font-size: 14px;
}

.footer a {
  color: inherit;
  font-weight: normal;
  text-decoration: none;
}

body.-notouch .footer a:hover {
  color: #205640;
}

.footer__top {
  padding: 0 0 23px;
}

.footer__logo {
  flex: 0 0 122px;
}

.footer__logo a {
  opacity: 0.4;
  transition: opacity 0.2s;
}

body.-notouch .footer__logo a:hover {
  opacity: 1;
}

.footer__middle {
  position: relative;
}

.footer__nav {
  position: absolute;
  top: -54px;
  left: 179px;
  font-weight: normal;
  font-size: 16px;
}

.footer__nav ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__nav ul li {
  margin: 0 30px 0 0;
  padding: 0;
}

.footer__nav ul li:last-child {
  margin-right: 0;
}

.footer__agent {
  margin-bottom: 20px;
}
.footer__agent-link {
  cursor: pointer;
  text-decoration: underline;
}
.footer__agent-link:hover {
  text-decoration: none;
}
@media (max-width: 1279px) {
  .footer__middle {
    padding: 23px 0;
    border-top: 1px solid rgb(0 0 0 / 0.1);
  }

  .footer__nav {
    position: relative;
    top: 0;
    left: 0;
  }
}
@media (max-width: 991px) {
  .footer__nav {
    margin-bottom: -10px;
  }

  .footer__nav ul {
    flex-wrap: wrap;
  }

  .footer__nav ul li {
    width: 50%;
    margin: 0 0 10px;
    padding-right: 15px;
  }
}
@media (max-width: 374px) {
  .footer__nav ul li {
    font-size: 15px;
  }
}

.footer__cosial ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer__cosial ul li {
  margin: 0 12px 0 0;
  padding: 0;
}

.footer__cosial ul li:last-child {
  margin-right: 0;
}

.footer__cosial ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #dcebd8;
}

.footer__cosial ul li a svg {
  opacity: 0.6;
  transition: opacity 0.1s, fill 0.1s;
}

body.-notouch .footer__cosial ul li a:hover {
  background: #509c3b;
}

body.-notouch .footer__cosial ul li a:hover svg {
  opacity: 1;
  fill: #fff;
}

body.-notouch .footer__cosial ul li a:active {
  background: #488c35;
}

.footer__bottom {
  padding: 23px 0 0;
  border-top: 1px solid rgb(0 0 0 / 0.1);
}

.footer__company {
  flex: 0 0 136px;
  margin-right: 43px;
}

.footer__company a {
  opacity: 0.4;
  transition: opacity 0.2s;
}

body.-notouch .footer__company a:hover {
  opacity: 1;
}

.footer__policy {
  margin-right: 40px;
  color: #8c8f8c;
}

.footer__policy p {
  margin: 0 0 8px;
}

.footer__policy p:last-child {
  margin: 0;
}

.footer__policy a {
  color: #3f4440;
}

.footer__dev {
  flex: 0 0 112px;
}

.footer__dev a img {
  opacity: 0.4;
  transition: opacity 0.2s;
}

.footer__dev span {
  display: none;
  color: #8c8f8c;
  font-weight: 300;
}

body.-notouch .footer__dev a:hover img {
  opacity: 1;
}
@media (max-width: 991px) {
  .footer__policy {
    margin-right: 35px;
  }
}
@media (max-width: 767px) {
  .footer__company {
    margin-right: 0;
    margin-bottom: 23px;
    text-align: center;
  }

  .footer__policy {
    margin-right: 0;
    text-align: center;
  }

  .footer__dev {
    margin-top: 23px;
    padding-top: 23px;
    border-top: 1px solid rgb(0 0 0 / 0.1);
    text-align: center;
  }

  .footer__dev a {
    display: inline-flex;
    align-items: center;
  }

  .footer__dev span {
    display: block;
    max-width: 200px;
    margin-left: 20px;
    line-height: 1.2;
    text-align: left;
  }
}
@media (max-width: 575px) {
  .footer__policy {
    font-size: 12px;
  }

  .footer__policy a {
    font-size: 14px;
  }

  .footer__dev span {
    max-width: 120px;
    font-size: 12px;
  }
}
</style>
