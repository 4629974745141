import { reactive } from "vue";
const filterStore = {
  state: reactive({
    filterRooms: localStorage.getItem("filterRooms")
      ? JSON.parse(localStorage.getItem("filterRooms"))
      : null,
    filterQueues: localStorage.getItem("filterQueues")
      ? JSON.parse(localStorage.getItem("filterQueues"))
      : null,
    filterBuildings: localStorage.getItem("filterBuildings")
      ? JSON.parse(localStorage.getItem("filterBuildings"))
      : null,
    // filterDecorations: localStorage.getItem("filterDecorations")
    //   ? JSON.parse(localStorage.getItem("filterDecorations"))
    //   : [],
    filterTypes: localStorage.getItem("filterTypes")
      ? JSON.parse(localStorage.getItem("filterTypes"))
      : null,
    filterFeats: localStorage.getItem("filterFeats")
      ? JSON.parse(localStorage.getItem("filterFeats"))
      : [],
    filterSquare: localStorage.getItem("filterSquare")
      ? JSON.parse(localStorage.getItem("filterSquare"))
      : [null, null],
    filterFloor: localStorage.getItem("filterFloor")
      ? JSON.parse(localStorage.getItem("filterFloor"))
      : [null, null],
    filterCost: localStorage.getItem("filterCost")
      ? JSON.parse(localStorage.getItem("filterCost"))
      : [null, null],
  }),
  setFilterRooms(id) {
    this.state.filterRooms = id;
    const parsed = JSON.stringify(id);
    localStorage.setItem("filterRooms", parsed);
  },
  setFilterQueues(id) {
    this.state.filterQueues = id;
    const parsed = JSON.stringify(id);
    localStorage.setItem("filterQueues", parsed);
  },
  setFilterBuildings(id) {
    this.state.filterBuildings = id;
    const parsed = JSON.stringify(id);
    localStorage.setItem("filterBuildings", parsed);
  },
  // setFilterDecorations(data) {
  //   this.state.filterDecorations = data;
  //   const parsed = JSON.stringify(data);
  //   localStorage.setItem("filterDecorations", parsed);
  // },
  setFilterTypes(data) {
    this.state.filterTypes = data;
    const parsed = JSON.stringify(data);
    localStorage.setItem("filterTypes", parsed);
  },
  setFilterFeats(data) {
    this.state.filterFeats = data;
    const parsed = JSON.stringify(data);
    localStorage.setItem("filterFeats", parsed);
  },
  setFilterSquare(data) {
    this.state.filterSquare = data;
    const parsed = JSON.stringify(data);
    localStorage.setItem("filterSquare", parsed);
  },
  setFilterFloor(data) {
    this.state.filterFloor = data;
    const parsed = JSON.stringify(data);
    localStorage.setItem("filterFloor", parsed);
  },
  setFilterCost(data) {
    this.state.filterCost = data;
    const parsed = JSON.stringify(data);
    localStorage.setItem("filterCost", parsed);
  },
};
export default filterStore;
