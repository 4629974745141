<template>
  <div class="buildings-choose__wrap">
    <div class="buildings-choose">
      <div class="background">
        <img
          loading="lazy"
          width="2161"
          height="1351"
          src="@i/plan-archive.jpg"
          alt="План корпусов"
          @load="mainImageLoaded = true"
        />
      </div>
      <div v-if="dots.length > 0 && mainImageLoaded" class="tooltip__wrap">
        <svg
          ref="area"
          class="tooltip__area"
          viewBox="0 0 1440 900"
          @mousemove="onMousemove"
        >
          <path
            v-for="dot in dots"
            :key="dot.id"
            :d="dot.points"
            :data-id="dot.id"
            :class="{
              active: dot.isActive && dot.isAvailable,
              unavailable: !dot.isAvailable,
            }"
            @click="onClick(dot)"
          />
        </svg>
        <Item
          v-for="dot in dots"
          :key="dot.id"
          :dot="dot"
          :class="{
            active: dot.isActive && dot.isAvailable,
            'with-text-hover': dot.isHover && dot.tooltipText,
          }"
          @close="dot.isActive = false"
        />
      </div>
    </div>
    <div ref="helper" class="swiper-helper">
      <div class="swiper-helper__inner" />
    </div>
    <ul ref="nav" class="purchase__nav d-flex flex-wrap justify-content-center">
      <li>
        <router-link
          :to="{ name: 'Purchase' }"
          class="btn btn-default btn-lime"
          v-html="minMortgage"
        ></router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'Purchase' }"
          class="btn btn-default btn-lime"
        >
          Рассрочка 0%
        </router-link>
      </li>
      <li>
        <a
          class="btn btn-default btn-lime"
          data-popup-with-chats
          data-popup-with-chats-title="Trade-in"
        >
          Trade-in
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { gsap } from "gsap";
import Item from "@/components/planArchive/Item.vue";
import { is_touch } from "@/helpers.js";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
export default {
  components: {
    Item,
  },
  inject: ["mq", "mainStore"],
  data() {
    return {
      mainImageLoaded: false,
      dots: [
        {
          id: 1,
          title: "Корпус 1",
          text: "Выдача ключей",
          floors: this.$buildings[1].floors[1],
          points:
            "M1085.5 197L1081.5 177H906L902 534L904 544.5L1071.5 546.5L1082 341L1081.5 334.5L1083.5 294.5L1082.5 290.5L1085 247L1084 242.5L1085.5 197Z",
          isHover: false,
          isActive: false,
          isAvailable: this.$buildings[1].isAvailable,
        },
        {
          id: 2,
          title: "Корпус 2",
          text: "Выдача ключей",
          floors: this.$buildings[2].floors[1],
          points:
            "M1299 289.5H1106L1085 638L1094 691.5L1277.5 690.5L1316.5 333L1299 289.5Z",
          isActive: false,
          isAvailable: this.$buildings[2].isAvailable,
        },
        {
          id: 4,
          title: "Корпус 4",
          text: "Старт продаж!",
          custom_popup: false,
          floors: this.$buildings[4].floors[1],
          points:
            "M570.5 288L378 287.5L358.5 331L400.5 691.5L585.5 692.5L595.5 638H594.5L593.5 616H592.5L592 596H591V576H589.5V555.5H588V534H586.5V513H585V492H584L583.5 470.5H582.5V448.5H581V427H579.5L579 404H578V382H576.5V359.5H575V336.5H573.5V313.5H572L570.5 288Z",
          isHover: false,
          isActive: false,
          isAvailable: true,
          position: 'right',
          isAvailable: this.$buildings[4].isAvailable,
          // tooltipText: "Старт продаж",
        },
        {
          id: 5,
          title: "Корпус 5",
          text: "Старт продаж!",
          custom_popup: false,
          floors: this.$buildings[5].floors[1],
          points:
            "M604 155.5L621.5 464.5L683 463.5V475H742.5L743 471H768V460H783.5L785 435.5H783L783.5 416.5H782.5L783 398.5H782L783 380H782L783 362H781.5L782.5 343.5H781L782 324.5H780.5L781.5 305.5H780.5L781.5 286.5H780L781 267H779.5L780.5 247.5H779.5L780 227.5H779L780 208H778.5L779.5 188H778L779 168H778L779 147.5H777.5L777 125H651L649.5 130H638L636.5 133.5H626L624.5 139.5H608.5L604 155.5Z",
          isHover: false,
          isActive: false,
          isAvailable: true,
          position: 'bottom',
          isAvailable: this.$buildings[5].isAvailable,
        },
        {
          id: 6,
          title: "Корпус 6",
          text: "Выдача ключей",
          floors: this.$buildings[6].floors[1],
          points:
            "M620 626.5L623 693H829.5V625L828 597H813.5V594.5H740V597H712V594.5H625L620 626.5Z",
          isHover: false,
          isActive: false,
          isAvailable: this.$buildings[6].isAvailable,
          isApartments: true,
        },
      ],
    };
  },
  computed: {
    minMortgage() {
      let text = "Ипотека";
      if (this.mainStore.state.data?.mortgage.min_mortgage_percent) {
        text =
          "Ипотека от&nbsp;" +
          this.mainStore.state.data.mortgage.min_mortgage_percent +
          "%";
      }
      return text;
    },
  },
  watch: {
    mainImageLoaded() {
      this.$nextTick(() => {
        this.$Progress.finish();
        if (!is_touch()) {
          gsap
            .timeline({
              scrollTrigger: {
                trigger: this.$el,
                start: "top center",
                //markers: true,
                //end: 'center center',
                toggleActions: "play none none reverse",
              },
            })
            .fromTo(
              this.$refs.area.children,
              {
                opacity: 0,
              },
              {
                opacity: 0.2,
                transitionDuration: 0,
                duration: 0.8,
                clearProps: "all",
                stagger: { each: 0.2 },
              },
              0
            )
            .from(
              this.$el.querySelectorAll(".tooltip__dot"),
              {
                transitionDuration: 0,
                duration: 0.8,
                opacity: 0,
                y: 20,
                stagger: { each: 0.2 },
              },
              0
            );
        }

        if (window.matchMedia("(max-width: 1120px)").matches) {
          const block = this.$el.querySelector(".buildings-choose");
          const building1 = this.$el.querySelector(".tooltip__dot");
          const scrollLeft = building1.offsetLeft - window.innerWidth / 2;
          block.scrollLeft = scrollLeft;

          block.addEventListener("touchend", () => {
            if (block.scrollLeft != scrollLeft) {
              gsap.to(this.$refs.helper, { autoAlpha: 0, duration: 0.3 });
            }
          });
        }
      });
    },
  },
  mounted() {
    if (!is_touch()) {
      gsap.from(this.$refs.nav.children, {
        y: 150,
        autoAlpha: 0,
        scrollTrigger: {
          trigger: this.$refs.nav,
          toggleActions: "play reverse play reverse",
        },
        stagger: { each: 0.1 },
      });
    }
  },
  methods: {
    onMousemove({ target }) {
      const { dataset } = target;
      if (dataset.id !== undefined) {
        const id = +dataset.id;
        const dot = this.dots.find((item) => item.id === id);
        this.togglePopup(dot);
        return;
      }
      target === this.$refs.area && this.hidePopup();
    },
    onClick(dot) {
      !dot.isAvailable && window.popupWithChats.showCallback({ title: 'Узнать подробнее' });
    },
    togglePopup(dot) {
      this.hidePopup();
      this.$refs.helper.style.display = "none";
      dot.isHover = true;
      dot.isActive = true;
      if (this.mq.smMinus) {
        const block = this.$el.querySelector(".buildings-choose");
        const building1 = this.$el.querySelector(".tooltip__dot");
        gsap.to(block, {
          scrollTo: { x: building1.offsetLeft - window.innerWidth / 2 },
          duration: 0.2,
          ease: "power2.inOut",
        });
      }
    },
    hidePopup() {
      this.dots.filter((item) => {
        item.isHover = false;
        item.isActive = false;
      });
    },
  },
};
</script>

<style scoped>
@keyframes swipe {
  0% {
    transform: translateX(160px);
  }

  20% {
    transform: translateX(160px);
  }

  100% {
    transform: translateX(0);
  }
}

@media (max-width: 1120px) {
  .background,
  .tooltip__wrap {
    width: 1120px;
    height: 700px;
    padding: 0;
  }

  .buildings-choose__wrap::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 30px;
    height: 100%;
    background: linear-gradient(
      to right,
      rgb(255 255 255 / 1) 0%,
      rgb(255 255 255 / 0) 100%
    );
    pointer-events: none;
  }

  .buildings-choose__wrap::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 30px;
    height: 100%;
    background: linear-gradient(
      to right,
      rgb(255 255 255 / 0) 0%,
      rgb(255 255 255 / 1) 100%
    );
    pointer-events: none;
  }
}

@media (pointer: fine) {
  .swiper-helper {
    display: none !important;
  }
}

@media (min-width: 1121px) {
  .swiper-helper {
    display: none !important;
  }
}

.buildings-choose__wrap {
  position: relative;
}

.buildings-choose {
  position: relative;
  z-index: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.background {
  position: relative;
  z-index: -1;
  padding-bottom: 62.51735%;
  background: #b6acaa;
}

.background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 33%;
  background: linear-gradient(
    0deg,
    rgb(63 68 64 / 0) 0%,
    rgb(63 68 64 / 0.5) 100%
  );
  pointer-events: none;
}

.background::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 33%;
  background: linear-gradient(
    180deg,
    rgb(63 68 64 / 0) 0%,
    rgb(63 68 64 / 0.5) 100%
  );
  pointer-events: none;
}

.background img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: auto;
}

.tooltip__dot:nth-of-type(1) {
  top: 40%;
  left: 68.5%;
}

.tooltip__dot:nth-of-type(2) {
  top: 55%;
  left: 83%;
}

.tooltip__dot:nth-of-type(3) {
  top: 55%;
  left: 33%;
}

.tooltip__dot:nth-of-type(4) {
  top: 33%;
  left: 48%;
}

.tooltip__dot:nth-of-type(5) {
  top: 71.5%;
  left: 50.5%;
}

.tooltip__popup {
  cursor: pointer;
}

.tooltip__area path,
.tooltip__area rect,
.tooltip__area polygon {
  cursor: default;
}

body.-notouch .tooltip__area path.active,
body.-notouch .tooltip__area rect.active,
body.-notouch .tooltip__area polygon.active {
  opacity: 0.6;
}

body.-notouch .tooltip__area path.unavailable,
body.-notouch .tooltip__area rect.unavailable,
body.-notouch .tooltip__area polygon.unavailable {
  cursor: pointer;
}

.swiper-helper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 150px;
  background: linear-gradient(
    0deg,
    rgb(255 255 255 / 0) 0%,
    rgb(255 255 255 / 0.7) 50%,
    rgb(255 255 255 / 1) 100%
  );
  pointer-events: none;
}

.swiper-helper__inner {
  position: absolute;
  top: 30px;
  left: 50%;
  width: 190px;
  height: 30px;
  margin-left: -95px;
}

.swiper-helper__inner::after {
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  border: 5px solid #509c3b;
  border-radius: 50%;
  animation: swipe 1.25s ease-out infinite;
}

.purchase__nav {
  position: absolute;
  right: 0;
  bottom: 28px;
  left: 0;
  margin: 0;
  padding: 0 15px;
  list-style: none;
}

.purchase__nav li {
  margin: 0 8px;
  padding: 0;
}

@media (max-width: 991px) {
  .purchase__nav {
    bottom: 20px;
  }

  .purchase__nav li {
    margin: 0 4px 8px;
  }

  .purchase__nav .btn {
    padding: 7px 15px 10px !important;
    font-size: 18px !important;
  }
}

@media (max-width: 767px) {
  .purchase__nav {
    bottom: 10px;
  }
}

@media (max-width: 374px) {
  .purchase__nav li {
    margin: 0 2px 8px;
  }

  .purchase__nav .btn {
    padding-right: 13px !important;
    padding-left: 13px !important;
  }
}
</style>
