<template>
  <div :class="{'ms-key-lable': true,  'ms-key-lable_orange': orange}" >
    <div class="ms-key-lable__content">
      <div v-if="!hideIcon" class="ms-key-lable__icon">
        <svg
          width="24"
          height="30"
          viewBox="0 0 24 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.4113 18.7971L11.8232 17.3852L8.85474 14.4168L0.679199 22.5923L0.725379 25.4186L3.64766 25.5608L6.05294 25.6147L5.99894 23.2095L8.25994 23.4077L8.06174 21.1467L10.4661 21.2015L10.4113 18.7971ZM9.44254 20.1779L9.40194 18.3923L10.4089 17.3852L8.85474 15.831L1.68599 22.9997L1.70994 24.4653L3.68318 24.5613L5.0297 24.5915L4.97415 22.1158L7.15964 22.3074L6.96804 20.1215L9.44254 20.1779Z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.3234 19.3245L17.8402 17.3959L13.7852 16.3094L10.7927 27.4774L12.2458 29.9019L14.8477 28.5639L16.9577 27.408L15.7083 25.352L17.7655 24.3932L16.4634 22.5342L18.5731 21.3794L17.3234 19.3245ZM17.1748 21.0048L16.2468 19.4787L16.6154 18.103L14.4923 17.5342L11.8683 27.3268L12.6219 28.5841L14.3787 27.6806L15.56 27.0335L14.274 24.9173L16.2625 23.9904L15.0036 22.1932L17.1748 21.0048Z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8328 16.8231C16.2165 16.9259 16.6019 16.9756 16.9812 16.9767C18.962 16.9824 20.7767 15.6624 21.3136 13.6588C21.9533 11.2715 20.5365 8.8177 18.1493 8.1781C17.5653 8.02161 16.9774 7.98818 16.413 8.0619C15.9182 8.12653 15.4415 8.27351 15.0015 8.49212C13.8915 9.0436 13.0146 10.051 12.6685 11.3424C12.0566 13.6261 13.3266 15.9707 15.5277 16.7298C15.6275 16.7642 15.7293 16.7954 15.8328 16.8231ZM15.574 17.7891C15.5815 17.7911 15.589 17.7931 15.5966 17.7951C18.5102 18.5628 21.4989 16.8309 22.2795 13.9176C23.0621 10.9969 21.3288 7.99473 18.4081 7.21212C17.2106 6.89125 15.9994 6.99331 14.9331 7.42673C14.4328 7.63011 13.9643 7.90645 13.5442 8.24629C12.6759 8.94863 12.0138 9.92221 11.7026 11.0835C11.0617 13.4754 12.1081 15.9218 14.1121 17.1592C14.5552 17.4329 15.0451 17.6474 15.574 17.7891Z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17.0165 12.6511C17.3694 12.7457 17.7279 12.669 18.0037 12.4721C18.1683 12.3546 18.3034 12.1943 18.3904 12.0019C18.4164 11.9442 18.4382 11.8837 18.4551 11.8205C18.533 11.53 18.4948 11.2357 18.369 10.9869C18.2235 10.699 17.9607 10.472 17.6245 10.3819C17.525 10.3552 17.425 10.3422 17.3266 10.3416C16.8839 10.339 16.4724 10.5888 16.2718 10.9869C16.2362 11.0576 16.2072 11.133 16.1859 11.2125C16.1126 11.486 16.1422 11.7628 16.2509 12.0019C16.3912 12.3107 16.6634 12.5565 17.0165 12.6511ZM16.7577 13.617C17.1545 13.7233 17.555 13.7128 17.9227 13.6064C18.3467 13.4838 18.7272 13.2339 19.008 12.889C19.1958 12.6583 19.3391 12.3853 19.421 12.0794C19.4556 11.9505 19.4778 11.8212 19.4884 11.6928C19.5175 11.3393 19.4587 10.9922 19.327 10.6778C19.0787 10.0849 18.5712 9.60792 17.9058 9.42212C17.8983 9.42004 17.8909 9.418 17.8834 9.416C17.4449 9.29853 17.0018 9.3238 16.6035 9.46334C15.9479 9.69298 15.4134 10.2321 15.22 10.9537C14.9092 12.1138 15.5976 13.3062 16.7577 13.617Z"
          />
          <path
            d="M19.327 10.6778C19.4587 10.9922 19.5175 11.3393 19.4884 11.6928C19.6076 11.6466 19.7257 11.5964 19.8423 11.5424C21.0577 10.9794 22.0497 10.026 22.6604 8.834C23.2712 7.642 23.4655 6.2799 23.2125 4.96464C22.9595 3.64938 22.2737 2.45659 21.2643 1.57624C20.2549 0.695881 18.9799 0.178571 17.6425 0.106701C16.305 0.034831 14.982 0.412531 13.8841 1.17965C12.7861 1.94677 11.9765 3.05919 11.584 4.33976C11.2551 5.41295 11.235 6.55253 11.5193 7.62958C11.8317 7.50604 12.1532 7.41236 12.4794 7.34855C12.2495 6.45861 12.2686 5.51858 12.5401 4.63281C12.8671 3.56567 13.5419 2.63865 14.4568 1.99938C15.3718 1.36012 16.4743 1.04537 17.5888 1.10526C18.7034 1.16515 19.7658 1.59624 20.607 2.32987C21.4482 3.0635 22.0197 4.05749 22.2305 5.15354C22.4414 6.24959 22.2794 7.38467 21.7704 8.378C21.2615 9.3714 20.4348 10.1659 19.422 10.635C19.3905 10.6496 19.3588 10.6639 19.327 10.6778Z"
          />
          <path
            d="M18.4551 11.8205C18.533 11.53 18.4948 11.2357 18.369 10.9869C17.6815 11.1343 16.9667 11.136 16.2718 10.9869C16.2362 11.0576 16.2072 11.133 16.1859 11.2125C16.1126 11.486 16.1422 11.7628 16.2509 12.0019C16.9615 12.1307 17.6862 12.1295 18.3904 12.0019C18.4164 11.9442 18.4382 11.8837 18.4551 11.8205Z"
          />
          <path
            d="M15.5966 17.7951C15.589 17.7931 15.5815 17.7911 15.574 17.7891C15.0451 17.6474 14.5552 17.4329 14.1121 17.1592C12.7832 17.3309 11.3921 16.9063 10.3713 15.8856C8.62362 14.138 8.62362 11.3046 10.3713 9.557C11.2473 8.68101 12.3961 8.2441 13.5442 8.24629C13.9643 7.90645 14.4328 7.63011 14.9331 7.42673C14.1312 7.21587 13.2908 7.18981 12.4794 7.34855C12.1532 7.41236 11.8317 7.50604 11.5193 7.62958C10.8437 7.89671 10.2105 8.30349 9.66412 8.8499C7.52602 10.988 7.52602 14.4546 9.66412 16.5927C11.268 18.1965 13.6192 18.5973 15.5966 17.7951Z"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.369 10.9869C18.4948 11.2357 18.533 11.53 18.4551 11.8205C18.4382 11.8837 18.4164 11.9442 18.3904 12.0019C17.6862 12.1295 16.9615 12.1307 16.2509 12.0019C16.1422 11.7628 16.1126 11.486 16.1859 11.2125C16.2072 11.133 16.2362 11.0576 16.2718 10.9869C16.9667 11.136 17.6815 11.1343 18.369 10.9869Z"
          />
        </svg>
      </div>
      <div class="ms-key-lable__text">
        <div v-if="desc" class="ms-key-lable__text-desc" v-html="desc" />
        <div v-if="title" class="ms-key-lable__text-title" v-html="title" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroKeyLabel",
  props: {
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    orange: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="css" scoped>
.ms-key-lable {
  --mh: 30px;
  display: inline-flex;
  min-height: var(--mh);
  border-radius: 200px;
  padding: 8px 12px;
  /* padding: 11px; */
  background: var(--ms-key-lable-background, #FFFFFF);
  color: var(--ms-key-lable-color, #C56046);
}
@media (min-width: 768px) {
  .ms-key-lable {
    --mh: 38px;
    padding: 12px 16px
  }
}
.ms-key-lable__content {
  display: flex;
  gap: 12px;
}
@media (min-width: 768px) {
  .ms-key-lable__content {
    gap: 20px;
  }
}
.ms-key-lable__icon svg {
  display: block;
  width: 24px;
  height: var(--mh);
  fill: currentColor;
}
@media (min-width: 768px) {
  .ms-key-lable__icon svg {
    width: 27px;
  }
}
.ms-key-lable__text {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 2px;
  margin-top: auto;
  margin-bottom: auto;
}
.ms-key-lable__text-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 98%; /* 11.76px */
  letter-spacing: -0.24px;
  text-align: right;
}
.ms-key-lable__text-desc {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 98%; /* 15.68px */
  letter-spacing: -0.32px;
}

.ms-key-lable_orange {
  background-color: #C56046;
  color: #FFFFFF;
  flex-direction: column-reverse;
}

@media (max-width: 767px) {
  .ms-key-lable__text-title {
    text-align: left;
  }
}
</style>
