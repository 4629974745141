<template>
  <a
    ref="container"
    href="javascript:void(0)"
    class="play-camera cursor-zoom"
    aria-label="Открыть видео"
    @click="$vfm.show({ component: 'PopupLive' })"
  >
    <div ref="words" class="play-camera__words" />
    <div class="play-camera__icon"><Icon icon="live" /></div>
  </a>
</template>

<script>
import { gsap } from "gsap";
import { is_touch } from "@/helpers.js";

export default {
  mounted() {
    if (is_touch()) return;
    const container = this.$refs.container;
    const words = this.$refs.words;
    const rotationTL = gsap.to(words, {
      rotation: "360",
      duration: 30,
      ease: "none",
      repeat: -1,
    });
    container.addEventListener("mouseenter", () => rotationTL.pause());
    container.addEventListener("mouseleave", () => rotationTL.resume());
  },
};
</script>

<style scoped>
.play-camera {
  --size: 178px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
}

.play-camera__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42%;
  height: 42%;
  border-radius: 50%;
  background: linear-gradient(180deg, #509c3b 0%, #95c389 100%);
}

.play-camera__icon svg {
  width: 52%;
  height: auto;
  max-height: 100%;
}

body.-notouch .play-camera:hover .play-camera__icon {
  background: linear-gradient(180deg, #62a650 0%, #a0c994 100%);
}

body.-notouch .play-camera:active .play-camera__icon {
  background: linear-gradient(180deg, #498c36 0%, #86af7a 100%);
}

.play-camera__words {
  width: 100%;
  height: 100%;
  background: url("~@i/play-btn-words.png") 0 0 no-repeat;
  background-size: cover;
  border-radius: 50%;
}
</style>
