<template>
  <div class="tooltip__dot">
    <div class="tooltip__icon">
      <span class="tooltip__locked">
        <div class="tooltip__locked-id">{{ dot.id }}</div>
        <span>{{ dot.tooltipText }}</span>
      </span>
    </div>
    <div
      v-if="dot.isAvailable"
      :class="{
        tooltip__popup: true,
        tooltip__popup_bottom: dot.position === 'bottom',
        tooltip__popup_left: dot.position === 'left',
        tooltip__popup_right: dot.position === 'right',
      }"
    >
    <!-- tooltip__popup_bottom: dot.id !== 6 && dot.id !== 2,
    tooltip__popup_left: dot.id === 6, -->
      <div v-show="step == 1" class="step-1">
        <div class="h4" v-html="dot.title" />
        <div class="h4 tooltip__text" v-html="dot.text" />
        <div v-if="!dot.custom_popup" class="tooltip-table">
          <div v-if="dot.floors" class="tooltip-table__row">
            <div class="tooltip-table__td">
              <span>Этажей</span>
            </div>
            <div class="tooltip-table__td">
              <span>{{ dot.floors }}</span>
            </div>
          </div>
          <div class="tooltip-table__row">
            <div class="tooltip-table__td">
              <span>
                {{ dot.isApartments ? "Лотов" : "Квартир" }}
                в&nbsp;продаже
              </span>
            </div>
            <div class="tooltip-table__td">
              <span>{{ flats }}</span>
            </div>
          </div>
        </div>
        <div v-if="flats && !dot.custom_popup" class="tooltip__button">
          <button
            v-if="mq.lgPlus"
            class="btn btn-default btn-small w-100"
            @click="step = 2"
          >
            Выбрать этаж
          </button>
          <router-link
            v-else
            :to="{ name: 'PlanSearch' }"
            class="btn btn-micro btn-yellow w-100"
          >
            Подбор по параметрам
          </router-link>
        </div>
        <div class="tooltip__button">
          <button
            v-if="dot.custom_popup"
            class="btn btn-default btn-small w-100"
            @click="onCallback"
          >
            Узнать подробнее
          </button>
        </div>
      </div>
      <div v-if="current" v-show="step == 2" class="step-2">
        <div class="h4">Выберите этаж</div>
        <div class="tooltip__slider">
          <Slider
            v-model="current"
            :min="floorMinMax[0]"
            :max="floorMinMax[1]"
            :format="floorFilterFormat"
          />
        </div>
        <div class="tooltip__floorinfo" v-html="floorinfo" />
        <router-link
          v-if="hasFlats"
          :to="{
            name: 'PlanFloor',
            params: { korp: dot.id, floor: current },
          }"
          class="tooltip__btn btn btn-default btn-small w-100"
        >
          Перейти к планировке
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Slider from "@vueform/slider";
import _forEach from "lodash/forEach";
import _toNumber from "lodash/toNumber";
import _minBy from "lodash/minBy";
import _maxBy from "lodash/maxBy";
import _groupBy from "lodash/groupBy";
export default {
  components: {
    Slider,
  },
  inject: ["mq"],
  props: {
    dot: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  data() {
    return {
      step: 1,
      flats: 0,
      floors: [],
      floorMinMax: [null, null],
      current: null,
    };
  },
  computed: {
    hasFlats() {
      return !!this.floors.find((floor) => floor.id == this.current)
    },
    floorinfo() {
      let result = "";
      if (this.current) {
        let floor = this.floors.filter((el) => {
          return el.id == this.current;
        });
        if (floor[0] === undefined) {
          result = `На данный момент нет доступных квартир на&nbsp;${this.current} этаже.`;
        } else {
          const flatName = this.$filters.declOfNum(
            floor[0].flats,
            this.dot.isApartments
              ? ["лот", "лота", "лотов"]
              : ["квартира", "квартиры", "квартир"]
          );
          result = `В&nbsp;продаже <b>${floor[0].flats}
						${flatName}
						${this.$filters.squareFormat(
              floor[0].min
            )}&nbsp;-&nbsp;${this.$filters.squareFormat(floor[0].max)}</b>
						на&nbsp;${floor[0].id}&nbsp;этаже.`;
        }
      }
      return result;
    },
  },
  watch: {
    "dot.isActive"(isActive) {
      if (isActive) {
        this.step = 1;
      }
    },
  },

  async mounted() {
    try {
      if (this.dot.isAvailable) {
        const response = await axios.get(
          this.$endpoint +
            "building-objects/" +
            this.$project +
            "/properties?building=" +
            this.dot.id
        );
        const data = response.data.data.filter((flat) => {
          return flat.floor.number > 0;
        });

        this.flats = data.length;

        this.floorMinMax = [
          _minBy(data, "floor.number").floor.number,
          _maxBy(data, "floor.number").floor.number,
        ];

        this.current = this.floorMinMax[0];

        const result = [];
        const list = _groupBy(data, "floor.number");

        _forEach(list, (value, key) => {
          result.push({
            id: _toNumber(key),
            flats: value.length,
            min: _minBy(value, "layout.area").layout.area,
            max: _maxBy(value, "layout.area").layout.area,
          });
        });

        this.floors = result;
      }
    } catch (err) {
      //
    } finally {
      //
    }
  },
  methods: {
    floorFilterFormat: function (value) {
      return value.toFixed(0);
    },
    onCallback: function () {
      window.popupWithChats.showCallback({ title: 'Узнать подробнее' })
    }
  },
};
</script>

<style src="@vueform/slider/themes/default.css" />
<style scoped>
@import "~@css/slider.css";

.tooltip__slider {
  margin: 42px 0 16px;
}

.tooltip__slider :deep(.slider-base::before) {
  right: 0;
  left: 0;
}

.tooltip__popup::before,
.tooltip__popup::after {
  pointer-events: auto;
}

.tooltip__popup::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 24px;
}

.step-1 .h4:not(.tooltip__text) {
  margin-bottom: 0;
}

.tooltip__text {
  opacity: 0.76;
  color: #205640;
  font-family: Mabry;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
}

.tooltip__floorinfo {
  font-size: 16px;
}

.tooltip__btn {
  margin-top: 17px;
}

.tooltip__locked-id {
  display: inline-block;
  width: 24px;
  text-align: center;
}
/* @media (max-width: 1400px) and (min-width: 992px) {
} */
.tooltip__popup_bottom {
  top: 55px;
  bottom: auto;

}
.tooltip__popup_bottom::after {
  top: auto;
  bottom: 100%;
  border-color: rgb(255 255 255 / 0);
  border-bottom-color: #fff;
}

.tooltip__popup_left {
  left: 45px;

}

.tooltip__popup_left::after {
  left: 108px;
}

.tooltip__popup_right {
  left: 231px;
  top: -114px;
  bottom: auto;
}

.tooltip__popup_right::after {
  left: -11px;
  top: 103px;
  transform: rotate(90deg);
}
</style>
